<template>
  <div class="view-wrap">
    <div class="button">
      <el-button type="primary" round @click="AddData">
        <i class="el-icon-plus" /> 新增</el-button
      >
    </div>
    <div class="view">
      <SimpleTable
        :data="tableData"
        :tableFields="tableItem"
        :operation="operationEvent"
        :currentPage="searcForm.pageNum"
        :pageSize="searcForm.pageSize"
        :total="total"
        @sizeChange="changePageSize"
        @currentChange="changeCurrentPage"
      >
        <div slot="operation" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            content="修改"
            placement="top-start"
          >
            <el-button
              :disabled="row.data.type === 0"
              type="text"
              @click="EditData(row.data)"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
          <!--  -->
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top-start"
          >
            <el-button
              :disabled="row.data.type === 0"
              type="text"
              @click="DeleteData(row.data)"
              ><i class="el-icon-delete"></i
            ></el-button>
          </el-tooltip>
          <!--  -->
          <el-tooltip
            class="item"
            effect="dark"
            content="期初设置"
            placement="top-start"
          >
            <el-button
              :disabled="row.data.initialBalance !== undefined"
              type="text"
              @click="setting(row.data)"
              >期初设置
            </el-button>
          </el-tooltip>
        </div>
      </SimpleTable>
    </div>
    <miaDialog ref="AddOrEditForm" v-if="Visible">
      <div slot="content">
        <el-form
          ref="form"
          style="width: 70%; margin: 0 auto"
          :model="submitForm"
          :rules="submitRules"
          label-width="80px"
        >
          <!-- <el-form-item label="账户编号" prop="code" key="code">
            <el-input
              :disabled="forbid"
              v-model="submitForm.code"
              placeholder="请输入账户编号"
            ></el-input>
            
          </el-form-item> -->
          <el-form-item label="账户名称" prop="name" key="name">
            <el-input
              v-model="submitForm.name"
              placeholder="请输入账户名称"
            ></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitData">提交</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
    <miaDialog ref="InitialForm" v-if="InitialVisible">
      <div slot="content">
        <el-form
          ref="Initialform"
          style="width: 70%; margin: 0 auto"
          :model="InitialForm"
          :rules="InitialRules"
          label-width="120px"
        >
          <el-form-item
            label="期初余额"
            prop="initialPayment"
            key="initialPayment"
          >
            <el-input-number
              size="mini"
              v-model="InitialForm.initialPayment"
              controls-position="right"
              :min="0.0"
              :max="9999999.99"
              :precision="2"
            ></el-input-number>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitInitialData"
              >提交</el-button
            >
            <el-button @click="closeInitial">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import {
  GetAccountList,
  AddAccount,
  EditAccount,
  DeleteAccount,
  SettingInitial,
} from "@/api/account";
import { setInterval } from "@/until/auth.js";
export default {
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      tableItem: [
        { label: "操作", type: "operation" },
        // { prop: "code", label: "账户编码", type: "default" },
        { prop: "name", label: "账户名称", type: "default" },
        // { prop: "initialBalance", label: "期初余额", type: "price" },
        { prop: "balance", label: "当前余额", type: "price" },
      ],
      operationEvent: [
        { type: "edit", event: this.EditData },
        { type: "delete", event: this.DeleteData },
        { type: "setting", event: this.setting },
      ],
      Visible: false,
      forbid: false,
      submitForm: {
        code: "",
        name: "",
      },
      InitialForm: {
        accountId: "",
        initialPayment: "",
      },
      InitialRules: {
        initialPayment: [
          { required: true, trigger: "blur", message: "请输入期初余额" },
        ],
      },
      InitialVisible: false,
      type: 0, // 0修改 1新增
      submitRules: {
        code: [
          { required: true, message: "请输入账户编码", trigger: "blur" },
          {
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入账户名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      GetAccountList(this.searcForm).then((res) => {
        const { records, current, pages, total } = res.data;
        if (res.code === 1) {
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    EditData(data) {
      this.Visible = true;
      this.type = 0;
      this.submitForm.id = data.id;
      this.submitForm.code = data.code;
      this.submitForm.name = data.name;
      this.forbid = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("修改账户");
      });
    },
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.type) {
            AddAccount(this.submitForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          } else {
            EditAccount(this.submitForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
    closeInitial() {
      this.$refs.InitialForm.close();
    },
    DeleteData(data) {
      this.$confirm(`是否删除该账户？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        DeleteAccount(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    AddData() {
      this.Visible = true;
      this.forbid = false;
      this.submitForm = {
        code: "",
        name: "",
      };
      this.submitForm.code = "ZH" + setInterval(this.total);
      this.type = 1;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("新建账户");
      });
    },
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    setting(data) {
      this.InitialForm.accountId = data.id;
      this.InitialForm.initialPayment = undefined;
      this.InitialVisible = true;
      this.$nextTick(() => {
        this.$refs.InitialForm.init("账户期初设置");
      });
    },
    // 期初设置
    submitInitialData() {
      this.$refs["Initialform"].validate((valid) => {
        if (valid) {
          SettingInitial(this.InitialForm).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.closeInitial();
              this.getData();
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
